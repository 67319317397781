<template>
  <div>
    <div class="card rounded-large border-none mb-2">
      <div class="card-body">
        <div class="name">Customer detail</div>
        <div
          :class="
            profile.is_consent === 1
              ? 'icon-profile-consent mx-auto'
              : 'icon-profile mx-auto'
          "
          :style="'background-image: url(' + profile.picture + ');'"
        >
          <span v-if="!profile.picture">
            {{ profile.name ? profile.name.substring(1, 0).toUpperCase() : "" }}
          </span>
        </div>

        <div class="customer-name">
          {{ profile.name }} ({{ profile.member_id }})
        </div>

        <div class="text-center">
          <b-badge pill variant="personal" v-if="profile.is_company == 0"
            >Personal</b-badge
          >
          <b-badge pill variant="company" v-else
            >Company
            <span v-if="profile.company_information == 'ไม่จดทะเบียน'">
              : Not Register</span
            >
          </b-badge>
          <b-badge
            pill
            :variant="profile.is_consent == 1 ? 'personal' : 'company'"
            class="ml-2"
            >Consent : {{ profile.is_consent == 1 ? "Yes" : "No" }}</b-badge
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    profile: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {};
  },
  watch: {},
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
::v-deep .rounded-large {
  border-radius: 0.75rem;
  overflow: hidden;
}
::v-deep .card-body {
  h4 {
    font-weight: 600;
    color: rgb(96, 96, 96);
  }

  color: black;
}

.profile-img {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border: none;
  border-radius: 50%;
}
.panel-image {
  width: 100px;
  height: 100px;
  cursor: pointer;
  border: 3px solid transparent;
  border-radius: 50%;
  bottom: 0px;
}
.name {
  font-weight: 600;
}

.icon-profile {
  text-align: center;
  border-radius: 50px;
  width: 70px;
  height: 70px;
  padding: 10px 0;
  font-size: 35px;
  font-weight: 600;
  background-color: var(--theme-secondary-color);
  color: var(--primary-color);
  position: relative;
}
.icon-profile-consent {
  // background-image: url(var(--background));
  background-size: contain;
  padding: 5px 0;
  text-align: center;
  border: 3px solid #2eb85c;
  border-radius: 50px;
  width: 70px;
  height: 70px;
  font-size: 35px;
  font-weight: 600;
  background-color: var(--theme-secondary-color);
  color: var(--primary-color);
}

::v-deep .btn-secondary {
  background-color: transparent;
  border: none;
}

.customer-name {
  text-align: center;
  font-size: 18px;
  margin: 10px 0px;
}

body {
  min-height: 100vh;
  padding: 0 10vh;
  margin: 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-green {
  color: #2eb85c;
}
.text-error {
  color: red;
}

.badge {
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
}
.box {
  width: 100%;
  padding: 0.5rem;
  background-color: var(--theme-secondary-color);
  border: 1px solid var(--primary-color);
  border-radius: 0.5rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: var(--font-color);
}
.box-score {
  padding: 0.5rem 0.45rem;
  margin-right: 0.25rem;
  font-size: 12px;
  border-radius: 50%;
  background-color: var(--primary-color);
  color: var(--font-primary-color);
}

.badge-personal {
  background-color: rgb(39, 189, 167);
  color: white;
  padding: 0.4em 0.6em;
}
.badge-company {
  background-color: rgb(236, 99, 99);
  color: white;
  padding: 0.4em 0.6em;
}

.name {
  color: #888888;
}
</style>
